import React from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import IngredientCategories from './list';
import { Col, Row, Spinner } from 'reactstrap';
import {
    ADD_INGREDIENT_CATEGORY,
    UPDATE_INGREDIENT_CATEGORY,
    DELETE_INGREDIENT_CATEGORY,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
    INGREDIENT_CATEGORIES,
    INGREDIENT_CATEGORIES_SUBSCRIPTION,
} from 'queries';

export default function IngredientCategoriesContainer() {
    const [addIngredientCategory] = useMutation(ADD_INGREDIENT_CATEGORY);
    const [updateIngredientCategory] = useMutation(UPDATE_INGREDIENT_CATEGORY);
    const [deleteIngredientCategory] = useMutation(DELETE_INGREDIENT_CATEGORY);

    const {
        data: ingredientCategoriesData,
        loading: ingredientCategoriesLoading,
        refetch: ingredientCategoriesRefetch,
    } = useQuery(INGREDIENT_CATEGORIES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(INGREDIENT_CATEGORIES_SUBSCRIPTION, {
        onData: () => {
            ingredientCategoriesRefetch();
        },
    });

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const onCreateIngredientCategory = (data) => {
        addIngredientCategory({
            variables: {
                icon: data.icon,
                order: data.order,
                translations: data.translations.map((translation) => ({
                    title: translation.translation,
                    languageId: translation.language.id,
                })),
                defaultDaysUntilExpiry: 90,
            },
        });
    };

    const onUpdateIngredientCategory = (data) => {
        let variables = {
            updateIngredientCategoryId: data.id,
            order: data.order,
            translations: data.translations.map((translation) => ({
                title: translation.translation,
                languageId: translation.language.id,
            })),
        };
        if (data.icon) {
            variables.icon = data.icon;
        }
        updateIngredientCategory({
            variables,
        });
    };
    const onRemoveIngredientCategory = (data) => {
        deleteIngredientCategory({
            variables: {
                deleteIngredientCategoryId: data.id,
                newId: data.replacementId,
            },
        });
    };

    if (ingredientCategoriesLoading || languagesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <IngredientCategories
                ingredientCategories={
                    ingredientCategoriesData && ingredientCategoriesData.ingredientCategories
                        ? ingredientCategoriesData.ingredientCategories
                        : []
                }
                languages={
                    languagesData && languagesData.languages
                        ? languagesData.languages.map((lang) => ({
                              ...lang,
                              label: lang.title,
                              value: lang.id,
                          }))
                        : []
                }
                onCreateIngredientCategory={onCreateIngredientCategory}
                onUpdateIngredientCategory={onUpdateIngredientCategory}
                onRemoveIngredientCategory={onRemoveIngredientCategory}
            />
        </Col>
    );
}
