import { gql } from '@apollo/client';

export const CHEFS_SUBSCRIPTION = gql`
    subscription chefsSubscription {
        chefsSubscription
    }
`;

export const CHEFS = gql`
    query chefs {
        chefs {
            id
            name
            email
            active
        }
    }
`;

export const GET_CHEF = gql`
    query chef($chefId: Int!) {
        chef(id: $chefId) {
            id
            createdAt
            updatedAt
            active
            deactivationDate
            nickname
            name
            surname
            email
            phoneNumber
            youtube
            instagram
            description
            location
            title
            iconPath
            firstSetupDone
            recipesCount
            followersCount
            ordersCount
            language {
                id
                languageCode
                title
            }
        }
    }
`;

export const UPDATE_CHEF = gql`
    mutation updateChef(
        $id: Int!
        $nickname: String
        $name: String
        $surname: String
        $email: String
        $password: String
        $phoneNumber: String
        $youtube: String
        $instagram: String
        $description: String
        $location: String
        $title: String
        $icon: String
        $firstSetupDone: Boolean
        $individual: Boolean
        $languageId: Int
    ) {
        updateChef(
            id: $id
            languageId: $languageId
            nickname: $nickname
            email: $email
            phoneNumber: $phoneNumber
            youtube: $youtube
            instagram: $instagram
            name: $name
            surname: $surname
            password: $password
            description: $description
            location: $location
            title: $title
            icon: $icon
            firstSetupDone: $firstSetupDone
            individual: $individual
        ) {
            id
        }
    }
`;

export const CHANGE_CHEF_ACTIVE = gql`
    mutation changeChefActive($id: Int) {
        changeChefActive(id: $id) {
            id
        }
    }
`;
