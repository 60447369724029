import React, { useEffect, useMemo, useState } from 'react';
import {
    Row,
    Col,
    Table,
    Spinner,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    Button,
    Alert,
} from 'reactstrap';
import { useQuery, useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { useGetMyData } from 'utils';
import translate from 'translate';
import {
    POTENTIAL_INGREDIENTS,
    POTENTIAL_INGREDIENTS_SUBSCRIPTION,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
    INGREDIENT,
    REPLACE_OR_REMOVE_POTENTIAL_INGREDIENTS,
    INGREDIENTS_TO_REPLACE_POTENTIAL,
    ADD_INGREDIENT_TO_POTENTIAL,
} from 'queries';
import { MaterialSymbol } from 'react-material-symbols';
import Select from 'react-select';
import { timestampToStringFNS } from 'utils';
import IngredientForm from '../settings/ingredients/form';

export default function PotentialIngredientsTable() {
    const currentAdmin = useGetMyData();

    const [addIngredient] = useMutation(ADD_INGREDIENT_TO_POTENTIAL);
    const [replaceOrRemovePotentialIngredients] = useMutation(
        REPLACE_OR_REMOVE_POTENTIAL_INGREDIENTS
    );

    const [sortOption, setSortOption] = useState(null);
    const [creatingTranslations, setCreatingTranslations] = useState(true);
    const [translatedIngredients, setTranslatedIngredients] = useState([]);
    const [selectedIngredients, setSelectedIngredients] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const [declineReason, setDeclineReason] = useState('');

    const [alertData, setAlertData] = useState(null);

    const {
        data: potentialIngredientsData,
        loading: potentialIngredientsLoading,
        refetch: potentialIngredientsRefetch,
    } = useQuery(POTENTIAL_INGREDIENTS, {
        variables: {},
        fetchPolicy: 'network-only',
    });

    useSubscription(POTENTIAL_INGREDIENTS_SUBSCRIPTION, {
        onData: () => {
            potentialIngredientsRefetch({});
        },
    });

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const [getIngredients, { data: ingredientsData, loading: ingredientsLoading }] = useLazyQuery(
        INGREDIENTS_TO_REPLACE_POTENTIAL
    );

    const [getIngredient, { data: ingredientData, loading: ingredientLoading }] =
        useLazyQuery(INGREDIENT);

    useEffect(() => {
        if (potentialIngredientsData && potentialIngredientsData.potentialIngredients) {
            setCreatingTranslations(true);
            getTranslations(potentialIngredientsData.potentialIngredients);
        }
    }, [potentialIngredientsData]);

    const languages =
        languagesData && languagesData.languages
            ? languagesData.languages.map((lang) => ({
                  ...lang,
                  label: lang.title,
                  value: lang.id,
              }))
            : [];

    async function getTranslations(ingredients) {
        let allTranslations = [];

        for (const ingredient of ingredients) {
            let translations = [
                {
                    language: ingredient.language,
                    title: ingredient.title,
                    original: true,
                },
            ];
            for (const language of languages) {
                if (language.id !== translations[0].language.id) {
                    const title = await translate(translations[0].title, {
                        from: translations[0].language.languageCode,
                        to: language.languageCode,
                    });
                    translations.push({
                        language: {
                            id: language.id,
                            languageCode: language.languageCode,
                        },
                        title,
                        original: false,
                    });
                }
            }
            allTranslations.push({
                ...ingredient,
                translations,
            });
        }

        setTranslatedIngredients(allTranslations);
        setCreatingTranslations(false);
    }

    const onCreateIngredient = (data) => {
        addIngredient({
            variables: {
                allergens: data.allergens.map((allergen) => allergen.value),
                type: data.type,
                averagePrice:
                    parseFloat(data.averagePrice) > 0
                        ? parseFloat(parseFloat(data.averagePrice).toFixed(2))
                        : 0.0,
                categoryId: data.categoryId,
                fats: parseFloat(data.fats),
                fiber: parseFloat(data.fiber),
                intoleranceIds: data.intolerances.map((intolerance) => intolerance.value),
                kcal: parseFloat(data.kcal),
                proteins: parseFloat(data.proteins),
                saccharides: parseFloat(data.saccharides),
                sugars: parseFloat(data.sugars),
                translations: data.translations.map((translation) => ({
                    title: translation.title,
                    languageId: translation.language.id,
                    allAlternatives: translation.allAlternatives,
                })),
                visible: data.visible,
                sources: [],
            },
        }).then((response) => {
            getIngredient({
                variables: {
                    id: response.data.addIngredient.id,
                },
            });
        });
    };

    const replaceWithIngredient = () => {
        if (!ingredientData || !ingredientData.ingredient) {
            return;
        }
        replaceOrRemovePotentialIngredients({
            variables: {
                ids: selectedIngredients.map((ing) => ing.id),
                replaceWithId: ingredientData.ingredient.id,
            },
        })
            .then((response) => {
                setSelectedIngredients([]);
                setAlertData({
                    text: 'Ingrediencie boli nahradené!',
                    color: 'success',
                });
                setShowModal(false);
            })
            .catch((error) => {
                setAlertData({
                    text: 'Chyba pri nahradení ingrediencií!',
                    color: 'danger',
                });
            });
    };

    const removeIngredient = () => {
        if (
            declineReason ||
            window.confirm('Ste si istý, že chcete zamietnuť tieto ingrediencie bez dôvodu?')
        ) {
            replaceOrRemovePotentialIngredients({
                variables: {
                    ids: selectedIngredients.map((ing) => ing.id),
                    message: declineReason,
                },
            })
                .then((response) => {
                    setSelectedIngredients([]);
                    setAlertData({
                        text: 'Ingrediencie boli zmazané!',
                        color: 'success',
                    });
                    setShowModal(false);
                })
                .catch((error) => {
                    setAlertData({
                        text: 'Chyba pri mazaní ingrediencií!',
                        color: 'danger',
                    });
                });
        }
    };

    const sortedIngredients = useMemo(() => {
        if (!sortOption) {
            const adminLanguageId = currentAdmin.language.id;
            return translatedIngredients.sort((i1, i2) =>
                i1.translations.find((t) => t.language.id === adminLanguageId).title <
                i2.translations.find((t) => t.language.id === adminLanguageId).title
                    ? -1
                    : 1
            );
        }
        return translatedIngredients.sort((i1, i2) =>
            i1.translations.find((t) => t.language.id === sortOption.value).title <
            i2.translations.find((t) => t.language.id === sortOption.value).title
                ? -1
                : 1
        );
    }, [translatedIngredients, sortOption]);

    if (potentialIngredientsLoading || languagesLoading || creatingTranslations) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Row
            style={{
                paddingTop: '1em',
            }}
        >
            <Col>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={false}
                    name="ingredient-sort"
                    options={[
                        ...languages.map((lang) => ({
                            label: `Zoradiť podľa jazyku ${lang.title}`,
                            value: lang.id,
                        })),
                    ]}
                    value={sortOption}
                    onChange={(option) => {
                        setSortOption(option);
                    }}
                />
                <div className="mt-2 mb-2">
                    <Button
                        className="ta-center flex"
                        color="primary"
                        onClick={() => {
                            if (selectedIngredients.length > 0) {
                                getIngredient({
                                    variables: {
                                        id: -1,
                                    },
                                });
                                setShowModal(true);
                            }
                        }}
                    >
                        <MaterialSymbol
                            icon={'visibility'}
                            size={18}
                            color={'white'}
                            style={{
                                outline: 'none',
                                border: 'none',
                                backgroundColor: 'transparent',
                                marginRight: '10px',
                            }}
                        />
                        Ukázať detail zaškrnutých
                    </Button>
                </div>
                <Alert
                    color={alertData ? alertData.color : ''}
                    isOpen={alertData ?? false}
                    toggle={() => {
                        setAlertData(null);
                    }}
                >
                    {alertData?.text}
                </Alert>
                <Table hover>
                    <thead>
                        <tr>
                            <th width={'5%'}>Check</th>
                            {languages.map((lang) => (
                                <th key={lang.value}>{lang.label}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedIngredients.map((ingredient) => (
                            <tr
                                key={ingredient.id}
                                style={{
                                    cursor: 'pointer',
                                }}
                            >
                                <td>
                                    <FormGroup check>
                                        <Input
                                            type="checkbox"
                                            checked={selectedIngredients.find(
                                                (ing) => ing.id === ingredient.id
                                            )}
                                            onChange={() => {
                                                if (
                                                    selectedIngredients.find(
                                                        (ing) => ing.id === ingredient.id
                                                    )
                                                ) {
                                                    setSelectedIngredients(
                                                        selectedIngredients.filter(
                                                            (ing) => ing.id !== ingredient.id
                                                        )
                                                    );
                                                } else {
                                                    setSelectedIngredients([
                                                        ...selectedIngredients,
                                                        ingredient,
                                                    ]);
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </td>
                                {languages.map((lang) => (
                                    <td
                                        key={`${ingredient.id}-${lang.value}`}
                                        style={
                                            ingredient.translations.find(
                                                (t) => t.language.id === lang.value
                                            )?.original
                                                ? { fontWeight: 'bold' }
                                                : {}
                                        }
                                        onClick={() => {
                                            if (
                                                selectedIngredients.find(
                                                    (ing) => ing.id === ingredient.id
                                                )
                                            ) {
                                                setSelectedIngredients(
                                                    selectedIngredients.filter(
                                                        (ing) => ing.id !== ingredient.id
                                                    )
                                                );
                                            } else {
                                                setSelectedIngredients([
                                                    ...selectedIngredients,
                                                    ingredient,
                                                ]);
                                            }
                                        }}
                                    >
                                        {
                                            ingredient.translations.find(
                                                (t) => t.language.id === lang.value
                                            )?.title
                                        }
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <Modal
                    isOpen={showModal}
                    size="xl"
                    toggle={() => {
                        setSelectedIngredients([]);
                        setShowModal(false);
                    }}
                >
                    <ModalBody>
                        <div className="d-flex flex-column container-fluid p-2">
                            <h4>Detail vybraných ingrediencií</h4>
                        </div>
                        <div className="flex-fill p-2 d-flex flex-column justify-content-start">
                            <div>
                                <h5>Názvy</h5>
                                <p>{selectedIngredients.map((ing) => ing.title).join(', ')}</p>
                            </div>
                            <div className="">
                                <h5>Majitelia</h5>
                                <p>
                                    {selectedIngredients.map((ing) => ing.creator.name).join(', ')}
                                </p>
                            </div>
                            <div className="p">
                                <h5>Detaily</h5>
                                <div className="d-flex">
                                    <table className="flex-fill">
                                        <thead>
                                            <th>ID</th>
                                            <th>Názov</th>
                                            <th>Majiteľ</th>
                                            <th>Tiež používajú</th>
                                            <th>Vytvorené</th>
                                        </thead>
                                        {selectedIngredients.map((ing) => (
                                            <tr key={ing.id}>
                                                <td>{ing.id}</td>
                                                <td>{ing.title}</td>
                                                <td>{ing.creator.name}</td>
                                                <td>
                                                    {ing.chefs.map((chef) => chef.name).join(', ')}
                                                </td>
                                                <td>{timestampToStringFNS(ing.createdAt)}</td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h5>Odstrániť bez náhrady</h5>
                                <div className="d-flex flex-row justify-content-between">
                                    <Input
                                        id={`decline-reason`}
                                        value={declineReason}
                                        className="w-75"
                                        placeholder="Dôvod"
                                        type="text"
                                        onChange={(e) => {
                                            setDeclineReason(e.target.value);
                                        }}
                                    />

                                    <Button
                                        className="w-25 ms-2"
                                        color="primary"
                                        onClick={() => {
                                            if (selectedIngredients.length > 0) {
                                                removeIngredient();
                                            }
                                        }}
                                    >
                                        Odstrániť bez náhrady
                                    </Button>
                                </div>
                            </div>
                            <h6>alebo</h6>
                        </div>

                        <div>
                            <div className="d-flex flex-row w-auto justify-content-between">
                                <div className="d-flex flex-column">
                                    <h4>Hľadať v existujúcich</h4>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        isLoading={ingredientsLoading}
                                        name="ingredients"
                                        options={
                                            ingredientsData
                                                ? ingredientsData.ingredients.map((ing) => ({
                                                      label: ing.translations.find(
                                                          (t) =>
                                                              t.language.id ===
                                                              currentAdmin.language.id
                                                      ).title,
                                                      value: ing.id,
                                                  }))
                                                : []
                                        }
                                        value={sortOption}
                                        onChange={(option) => {
                                            getIngredient({
                                                variables: {
                                                    id: option ? option.value : '',
                                                },
                                            });
                                        }}
                                        onInputChange={(e) => {
                                            getIngredients({
                                                variables: {
                                                    alternativesSearch: false,
                                                    search: e,
                                                    languageId: currentAdmin.language.id,
                                                    limit: 20,
                                                },
                                            });
                                        }}
                                    />
                                    {ingredientData && ingredientData.ingredient && (
                                        <div>
                                            <div className="">
                                                <h5>Názov</h5>
                                                <p>
                                                    {ingredientData.ingredient.translations
                                                        .map(
                                                            (translation) =>
                                                                `${translation.title} (${translation.language.languageCode})`
                                                        )
                                                        .join(', ')}
                                                </p>
                                            </div>

                                            <div className="">
                                                <h5>Alternatívne názvy</h5>
                                                {ingredientData.ingredient.translations.map(
                                                    (translation) => (
                                                        <p key={translation.id}>
                                                            {`${translation.language.languageCode} -> ${translation.allAlternatives}`}
                                                        </p>
                                                    )
                                                )}
                                            </div>

                                            <div className="">
                                                <h5>Kategória</h5>
                                                <p>
                                                    {
                                                        ingredientData.ingredient.category.translations.find(
                                                            (t) =>
                                                                t.language.id ===
                                                                currentAdmin.language.id
                                                        ).title
                                                    }
                                                </p>
                                            </div>

                                            <div className="">
                                                <h5>Alergény</h5>
                                                <p>
                                                    {ingredientData.ingredient.allergens.join(', ')}
                                                </p>
                                            </div>

                                            <div className="">
                                                <h5>Intolerancie</h5>
                                                <p>
                                                    {ingredientData.ingredient.intolerances
                                                        .map(
                                                            (intolerance) =>
                                                                intolerance.translations.find(
                                                                    (t) =>
                                                                        t.language.id ===
                                                                        currentAdmin.language.id
                                                                ).title
                                                        )
                                                        .join(', ')}
                                                </p>
                                            </div>

                                            <div className="">
                                                <h5>Priemerná cena</h5>
                                                <p>{`${ingredientData.ingredient.averagePrice} €`}</p>
                                            </div>

                                            <div className="">
                                                <h5>Nutričné hodnoty</h5>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th>Energia</th>
                                                            <td>
                                                                {`${ingredientData.ingredient.kcal} kcal`}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Sacharidy</th>
                                                            <td>
                                                                {`${ingredientData.ingredient.saccharides} g`}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Z toho cukry</th>
                                                            <td>
                                                                {`${ingredientData.ingredient.sugars} g`}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tuky</th>
                                                            <td>
                                                                {`${ingredientData.ingredient.fats} g`}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Bielkoviny</th>
                                                            <td>
                                                                {`${ingredientData.ingredient.proteins} g`}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Vláknina</th>
                                                            <td>
                                                                {`${ingredientData.ingredient.fiber} g`}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {ingredientLoading && <Spinner />}
                                <div className="w-50">
                                    <h4>Vytvoriť ingredienciu</h4>
                                    <IngredientForm
                                        onSave={onCreateIngredient}
                                        ingredientId={null}
                                        ingredientData={null}
                                        ingredientLoading={false}
                                        languages={languagesData && languagesData.languages}
                                    />
                                </div>
                            </div>

                            <div className="d-flex flex-row w-auto justify-content-between">
                                <div className="d-flex flex-column flex-fill">
                                    <div>
                                        <Button
                                            className="ta-center flex w-auto"
                                            color="primary"
                                            disabled={!ingredientData || !ingredientData.ingredient}
                                            onClick={() => {
                                                if (selectedIngredients.length > 0) {
                                                    replaceWithIngredient();
                                                }
                                            }}
                                        >
                                            <MaterialSymbol
                                                icon={'visibility'}
                                                size={18}
                                                color={'white'}
                                                style={{
                                                    outline: 'none',
                                                    border: 'none',
                                                    backgroundColor: 'transparent',
                                                    marginRight: '10px',
                                                }}
                                            />
                                            {`Nahradiť ${selectedIngredients
                                                .map((ing) => ing.title)
                                                .join(', ')} za ${
                                                ingredientData?.ingredient?.translations.find(
                                                    (trans) =>
                                                        trans.language.id ===
                                                        currentAdmin.language.id
                                                )?.title
                                            }`}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Col>
        </Row>
    );
}
