import React from 'react';
import { Form, FormGroup, Label, Input, InputGroup, Button } from 'reactstrap';
import Select from 'react-select';
import ImageInput from 'components/imageInput';
import { isEmail, isValidPhoneNumber } from 'utils';
import { MaterialSymbol } from 'react-material-symbols';

export default function ChefProfile(props) {
    const {
        chefID,
        isAmbassador,
        setIsAmbassador,
        name,
        setName,
        surname,
        setSurname,
        phoneNumber,
        setPhoneNumber,
        language,
        setLanguage,
        nickname,
        setNickname,
        youtube,
        setYoutube,
        instagram,
        setInstagram,
        location,
        setLocation,
        description,
        setDescription,
        title,
        setTitle,
        iconPath,
        setIconPath,
        image,
        setImage,
        languagesOptions,
        email,
        setEmail,
        wrongEmailFormat,
        setWrongEmailFormat,
        password,
        setPassword,
        password2,
        setPassword2,
        password1Visible,
        setPassword1Visible,
        password2Visible,
        setPassword2Visible,
        passwordErrors,
        setPasswordErrors,
    } = props;

    const checkPasswords = (calledFromFirstPassword) => {
        if (password.length + password2.length === 0) {
            return;
        }
        let newErrors = [];
        if (
            (!calledFromFirstPassword && password !== password2) ||
            (password !== password2 && password2.length > 0)
        ) {
            newErrors.push('Heslá nie sú rovnaké!');
        }
        if (password.length < 6) {
            newErrors.push('Heslo musí mať aspoň 6 znakov!');
        }
        setPasswordErrors(newErrors);
    };

    return (
        <Form>
            <ImageInput
                iconPath={iconPath}
                setIconPath={setIconPath}
                image={image}
                setImage={setImage}
                size={'image'}
            />

            <FormGroup check>
                <Input
                    id="chef-ambassador"
                    type="checkbox"
                    checked={!isAmbassador}
                    onChange={() => {
                        if (
                            window.confirm(
                                'Ste si istý, že chcete spraviť zmeny v statuse v kuchára?'
                            )
                        ) {
                            setIsAmbassador(!isAmbassador);
                        }
                    }}
                />
                <Label check htmlFor="chef-ambassador">
                    Šéfkuchár je ambasádor
                </Label>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="chef-nickanem">Nickname</Label>
                <Input
                    id="chef-nickanem"
                    value={nickname}
                    onChange={(e) => {
                        setNickname(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="chef-name">Meno</Label>
                <Input
                    id="chef-name"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="chef-surname">Priezvisko</Label>
                <Input
                    id="chef-surname"
                    value={surname}
                    onChange={(e) => {
                        setSurname(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="chef-title">Titul</Label>
                <Input
                    id="chef-title"
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="chef-location">Lokácia</Label>
                <Input
                    id="chef-location"
                    value={location}
                    onChange={(e) => {
                        setLocation(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="chef-description">Popis</Label>
                <Input
                    id="chef-description"
                    type="textarea"
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="chef-instagram">Instagram</Label>
                <Input
                    id="chef-instagram"
                    value={instagram}
                    onChange={(e) => {
                        setInstagram(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="chef-youtube">Youtube</Label>
                <Input
                    id="chef-youtube"
                    value={youtube}
                    onChange={(e) => {
                        setYoutube(e.target.value);
                    }}
                />
            </FormGroup>

            <Input
                id="email_fake_for_browser_autofill"
                style={{
                    height: '0px',
                    padding: '0px',
                    border: '0px',
                }}
                value={''}
                type={'email'}
            />

            {chefID !== 'add' && (
                <FormGroup>
                    <Label htmlFor="chef-email">Email</Label>
                    <Input
                        id="chef-email"
                        invalid={wrongEmailFormat}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setWrongEmailFormat(false);
                        }}
                        onBlur={() => {
                            if (!isEmail(email)) {
                                setWrongEmailFormat(true);
                            }
                        }}
                    />
                </FormGroup>
            )}
            {wrongEmailFormat && <p>{'Email má nesprávny tvar!'}</p>}

            <Input
                id="password_fake_for_browser_autofill"
                style={{
                    height: '0px',
                    padding: '0px',
                    border: '0px',
                }}
                value={''}
                type={'password'}
            />

            {chefID !== 'add' && (
                <FormGroup>
                    <Label htmlFor="admin_password_1">Heslo</Label>
                    <InputGroup>
                        <Input
                            id="admin_password_1"
                            invalid={passwordErrors.length > 0}
                            value={password}
                            type={password1Visible ? 'text' : 'password'}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            onBlur={() => checkPasswords(true)}
                        />
                        <Button
                            onClick={() => {
                                setPassword1Visible(!password1Visible);
                            }}
                        >
                            <MaterialSymbol
                                icon={password1Visible ? 'visibility_off' : 'visibility'}
                                size={24}
                                fill
                                grade={-25}
                                color="white"
                            />
                        </Button>
                    </InputGroup>
                </FormGroup>
            )}
            {chefID !== 'add' && (
                <FormGroup>
                    <Label htmlFor="admin_password_2">Zopakujte heslo</Label>
                    <InputGroup>
                        <Input
                            id="admin_password_2"
                            invalid={passwordErrors.length > 0}
                            value={password2}
                            type={password2Visible ? 'text' : 'password'}
                            onChange={(e) => {
                                setPassword2(e.target.value);
                            }}
                            onBlur={() => {
                                checkPasswords();
                            }}
                        />
                        <Button
                            onClick={() => {
                                setPassword2Visible(!password2Visible);
                            }}
                        >
                            <MaterialSymbol
                                icon={password2Visible ? 'visibility_off' : 'visibility'}
                                size={24}
                                fill
                                grade={-25}
                                color="white"
                            />
                        </Button>
                    </InputGroup>
                </FormGroup>
            )}

            {passwordErrors.map((error, index) => (
                <p key={index}>{error}</p>
            ))}

            <FormGroup>
                <Label htmlFor="chef-phone-number">Mobilné číslo</Label>
                <Input
                    id="chef-phone-number"
                    placeholder="+99 999 999 999"
                    value={phoneNumber}
                    onChange={(e) => {
                        setPhoneNumber(e.target.value);
                    }}
                />
            </FormGroup>
            {!isValidPhoneNumber(phoneNumber) && (
                <p>Toto nie je validné mobilné číslo. Zadali ste ho v tvare +99 999 999 999?</p>
            )}

            <FormGroup>
                <Label htmlFor="chef-language">Jazyk</Label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={true}
                    name="chef-language"
                    options={languagesOptions}
                    value={language}
                    onChange={(lang) => {
                        setLanguage(lang);
                    }}
                />
            </FormGroup>
        </Form>
    );
}
