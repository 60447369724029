import { gql } from '@apollo/client';

export const POTENTIAL_INGREDIENTS_SUBSCRIPTION = gql`
    subscription potentialIngredientsSubscription {
        potentialIngredientsSubscription
    }
`;

export const POTENTIAL_INGREDIENTS = gql`
    query potentialIngredients($search: String, $languageId: Int, $limit: Int, $offset: Int) {
        potentialIngredients(
            search: $search
            languageId: $languageId
            offset: $offset
            limit: $limit
        ) {
            id
            creator {
                id
                name
            }
            chefs {
                id
            }
            createdAt
            title
            language {
                id
                languageCode
            }
        }
    }
`;

export const POTENTIAL_INGREDIENT = gql`
    query potentialIngredient($id: Int!) {
        potentialIngredient(id: $id) {
            id
            creator {
                id
                name
            }
            chefs {
                id
            }
            createdAt
            language {
                id
                languageCode
            }
            title
        }
    }
`;

export const REPLACE_OR_REMOVE_POTENTIAL_INGREDIENTS = gql`
    mutation replaceOrRemovePotentialIngredients(
        $ids: [Int!]!
        $message: String
        $replaceWithId: Int
    ) {
        replaceOrRemovePotentialIngredients(
            ids: $ids
            message: $message
            replaceWithId: $replaceWithId
        )
    }
`;

export const INGREDIENTS_TO_REPLACE_POTENTIAL = gql`
    query ingredientsToReplacePotential(
        $alternativesSearch: Boolean
        $search: String
        $languageId: Int
        $limit: Int
        $offset: Int
    ) {
        ingredients(
            alternativesSearch: $alternativesSearch
            search: $search
            languageId: $languageId
            limit: $limit
            offset: $offset
        ) {
            id
            translations {
                title
                language {
                    id
                    languageCode
                }
            }
        }
    }
`;

export const ADD_INGREDIENT_TO_POTENTIAL = gql`
    mutation addIngredient(
        $allergens: [Int!]!
        $categoryId: Int!
        $fats: Float
        $fiber: Float
        $intoleranceIds: [Int!]!
        $kcal: Int!
        $proteins: Float
        $saccharides: Float
        $sugars: Float
        $translations: [IngredientTranslationInput!]!
        $visible: Boolean!
        $averagePrice: Float
        $type: IngredientTypeEnum!
        $sources: [String]!
    ) {
        addIngredient(
            allergens: $allergens
            categoryId: $categoryId
            fats: $fats
            fiber: $fiber
            intoleranceIds: $intoleranceIds
            kcal: $kcal
            proteins: $proteins
            saccharides: $saccharides
            sugars: $sugars
            translations: $translations
            visible: $visible
            averagePrice: $averagePrice
            type: $type
            sources: $sources
        ) {
            id
            type
            translations {
                title
                allAlternatives
                language {
                    id
                    languageCode
                }
            }
            category {
                id
                translations {
                    language {
                        id
                        languageCode
                    }
                    title
                }
            }
            intolerances {
                id
                translations {
                    title
                    language {
                        id
                        languageCode
                    }
                }
            }
            averagePrice
            allergens
            fats
            fiber
            kcal
            proteins
            saccharides
            sugars
            visible
        }
    }
`;
