import { gql } from '@apollo/client';

export const INTOLERANCES_SUBSCRIPTION = gql`
    subscription intolerancesSubscription {
        intolerancesSubscription
    }
`;

export const INTOLERANCES = gql`
    query getIntolerances {
        intolerances {
            id
            updatedAt
            order
            iconPath
            translations {
                title
                language {
                    id
                    languageCode
                }
            }
        }
    }
`;

export const ADD_INTOLERANCE = gql`
    mutation addIntolerance(
        $icon: String
        $order: Int!
        $translations: [IntoleranceTranslationInput!]!
    ) {
        addIntolerance(icon: $icon, order: $order, translations: $translations) {
            id
        }
    }
`;

export const UPDATE_INTOLERANCE = gql`
    mutation updateIntolerance(
        $updateIntoleranceId: Int!
        $icon: String
        $translations: [IntoleranceTranslationInput!]!
        $order: Int
    ) {
        updateIntolerance(
            id: $updateIntoleranceId
            icon: $icon
            translations: $translations
            order: $order
        ) {
            id
        }
    }
`;

export const DELETE_INTOLERANCE = gql`
    mutation deleteIntolerance($deleteIIntoleranceId: Int!, $newId: Int!) {
        deleteIntolerance(id: $deleteIntoleranceId, newId: $newId) {
            id
        }
    }
`;
