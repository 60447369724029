import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import Profile from '../components/profile';
import { useParams } from 'react-router-dom';
import { Button, Col, Row, Spinner, Alert } from 'reactstrap';

import {
    GET_CHEF,
    UPDATE_CHEF,
    CHANGE_CHEF_ACTIVE,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
} from 'queries';
import { useGetMyData, isEmail, isValidPhoneNumber } from 'utils';

export default function ChefProfileContainer() {
    const { chefID } = useParams();

    const currentAdmin = useGetMyData();

    const [updateChef] = useMutation(UPDATE_CHEF);
    const [changeChefActive] = useMutation(CHANGE_CHEF_ACTIVE);

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const {
        data: chefData,
        loading: chefLoading,
        refetch: chefRefetch,
    } = useQuery(GET_CHEF, {
        variables: {
            chefId: parseInt(chefID),
        },
        fetchPolicy: 'network-only',
    });

    const [email, setEmail] = useState('');
    const [wrongEmailFormat, setWrongEmailFormat] = useState(false);

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [password1Visible, setPassword1Visible] = useState(false);
    const [password2Visible, setPassword2Visible] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState([]);

    const [isAmbassador, setIsAmbassador] = useState(false);

    const [nickname, setNickname] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');

    const [phoneNumber, setPhoneNumber] = useState('');
    const [youtube, setYoutube] = useState('');
    const [instagram, setInstagram] = useState('');

    const [language, setLanguage] = useState(null);
    const [location, setLocation] = useState('');
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');

    const [image, setImage] = useState(null);
    const [iconPath, setIconPath] = useState(null);

    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);

    const [error, setError] = useState(null);

    const onDismissSaved = () => setSaved(false);
    const onDismissError = () => setError(null);

    useEffect(() => {
        chefRefetch({
            chefId: parseInt(chefID),
        });
    }, [chefID]);

    useEffect(() => {
        if (chefData && chefData.chef) {
            setData();
        }
    }, [chefData]);

    const setData = () => {
        setEmail(chefData.chef.email);
        setIsAmbassador(chefData.chef.isAmbassador ?? false);

        setNickname(chefData.chef.nickname ?? '');
        setName(chefData.chef.name);
        setSurname(chefData.chef.surname);

        setPhoneNumber(chefData.chef.phoneNumber);
        setYoutube(chefData.chef.youtube ?? '');
        setInstagram(chefData.chef.instagram ?? '');
        setLanguage(chefData.chef.language ?? '');
        setLocation(chefData.chef.location ?? '');
        setDescription(chefData.chef.description ?? '');
        setTitle(chefData.chef.title ?? '');

        setLanguage({
            value: chefData.chef.language.id,
            label: `${chefData.chef.language.title} (${chefData.chef.language.languageCode})`,
        });

        setIconPath(chefData.chef.iconPath);
        setImage(null);
    };

    const changeChefActiveFunc = () => {
        changeChefActive({
            variables: {
                id: parseInt(chefID),
            },
        }).then(() => {
            setSaving(false);
            setSaved(true);
        });
    };

    const updateChefFunc = () => {
        let dataToSave = {
            id: parseInt(chefID),
            nickname,
            email,
            name,
            surname,
            phoneNumber,
            language: language.value,
            youtube,
            instagram,
            location,
            description,
            title,
        };
        if (password) {
            dataToSave = {
                ...dataToSave,
                password,
            };
        }
        if (image) {
            dataToSave = {
                ...dataToSave,
                icon: image,
            };
        }
        updateChef({
            variables: dataToSave,
        })
            .then(() => {
                setSaving(false);
                setSaved(true);
            })
            .catch((err) => {
                setError(err.message);
            });
    };

    const cannotSave =
        nickname.length === 0 ||
        name.length === 0 ||
        surname.length === 0 ||
        instagram.length === 0 ||
        youtube.length === 0 ||
        !isValidPhoneNumber(phoneNumber) ||
        language === null ||
        passwordErrors.length > 0 ||
        !isEmail(email);

    if (languagesLoading || chefLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <Profile
                chefID={parseInt(chefID)}
                isAmbassador={isAmbassador}
                setIsAmbassador={setIsAmbassador}
                name={name}
                setName={setName}
                surname={surname}
                setSurname={setSurname}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                language={language}
                setLanguage={setLanguage}
                iconPath={iconPath}
                setIconPath={setIconPath}
                image={image}
                setImage={setImage}
                nickname={nickname}
                setNickname={setNickname}
                youtube={youtube}
                setYoutube={setYoutube}
                instagram={instagram}
                setInstagram={setInstagram}
                location={location}
                setLocation={setLocation}
                description={description}
                setDescription={setDescription}
                title={title}
                setTitle={setTitle}
                email={email}
                setEmail={setEmail}
                wrongEmailFormat={wrongEmailFormat}
                setWrongEmailFormat={setWrongEmailFormat}
                password={password}
                setPassword={setPassword}
                password2={password2}
                setPassword2={setPassword2}
                password1Visible={password1Visible}
                setPassword1Visible={setPassword1Visible}
                password2Visible={password2Visible}
                setPassword2Visible={setPassword2Visible}
                passwordErrors={passwordErrors}
                setPasswordErrors={setPasswordErrors}
                languagesOptions={languagesData.languages.map((lang) => ({
                    value: lang.id,
                    label: `${lang.title} (${lang.languageCode})`,
                }))}
                currentAdmin={currentAdmin}
            />
            {!chefData.chef.active && (
                <Row xs="2">
                    <Col>
                        <Button
                            color="secondary"
                            onClick={() => {
                                setSaving(true);
                                changeChefActiveFunc();
                            }}
                        >
                            Znovuaktivovať šéfkuhcára
                        </Button>
                    </Col>
                    <Col className="flex jc-flex-end"></Col>
                </Row>
            )}
            <Alert color="info" isOpen={saved} toggle={onDismissSaved}>
                Zmeny boli uložené!
            </Alert>
            <Alert color="danger" isOpen={error} toggle={onDismissError}>
                {error}
            </Alert>
            <Row xs="2" style={{ marginBottom: '1rem' }}>
                <Col>
                    <Button color="secondary" onClick={setData}>
                        Zrušiť
                    </Button>
                </Col>
                <Col className="flex jc-flex-end">
                    <Button
                        color="primary"
                        disabled={cannotSave}
                        onClick={() => {
                            setSaving(true);
                            updateChefFunc();
                        }}
                    >
                        {saving && <Spinner color="light" size="sm"></Spinner>}
                        {!saving ? 'Uložiť' : ''}
                    </Button>
                </Col>
            </Row>
        </Col>
    );
}
