import React, { useState } from 'react';
import { Row, Col, Table, Spinner } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/client';
import { CHEFS, CHEFS_SUBSCRIPTION } from 'queries';
import { MaterialSymbol } from 'react-material-symbols';
import Select from 'react-select';
import { sortBasic, sortName } from 'configs/sortOptions';

export default function ChefsTable() {
    const navigate = useNavigate();

    const [sortOption, setSortOption] = useState(sortBasic);

    const {
        data: chefsData,
        loading: chefsLoading,
        refetch: chefsRefetch,
    } = useQuery(CHEFS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(CHEFS_SUBSCRIPTION, {
        onData: () => {
            chefsRefetch();
        },
    });

    if (chefsLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Row
            style={{
                paddingTop: '1em',
            }}
        >
            <Col>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={false}
                    name="chef-sort"
                    options={[sortBasic, sortName]}
                    value={sortOption}
                    onChange={(option) => {
                        setSortOption(option);
                    }}
                />
                <Table hover>
                    <thead>
                        <tr>
                            <th width="10%">ID</th>
                            <th width="30%">Meno</th>
                            <th width="40%">Email</th>
                            <th width="10%" className="ta-center">
                                Aktívny
                            </th>
                            <th width="10%" className="ta-center">
                                Overený
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...chefsData.chefs]
                            .sort((c1, c2) => {
                                if (sortOption.value === 'id') {
                                    return c1[sortOption.value] < c2[sortOption.value] ? -1 : 1;
                                }
                                return c1[sortOption.value].toLowerCase() <
                                    c2[sortOption.value].toLowerCase()
                                    ? -1
                                    : 1;
                            })
                            .map((chef) => (
                                <tr
                                    key={chef.id}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => navigate(`/chefs/${chef.id}/profile`)}
                                >
                                    <th scope="row">{chef.id}</th>
                                    <td>
                                        <span>{chef.fullName}</span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="11"
                                            viewBox="0 0 10 11"
                                            fill="none"
                                            style={{ marginLeft: '0.5em' }}
                                        >
                                            <path
                                                d="M8.80718 2.32495L5.71235 1.06034C5.26746 0.875721 4.75488 0.875721 4.31 1.06034L1.2055 2.32495C0.983055 2.41726 0.847656 2.62034 0.847656 2.84187V5.82341C0.847656 6.92187 1.40859 7.95572 2.33704 8.59264L4.31 9.94034C4.72587 10.2265 5.27714 10.2265 5.693 9.94034L7.66596 8.59264C8.60408 7.95572 9.15535 6.92187 9.15535 5.82341V2.84187C9.15535 2.61111 9.01028 2.40803 8.79751 2.32495H8.80718ZM5.93479 7.0788L5.66399 6.26649H4.32934L4.06822 7.0788H3.01404L4.34869 3.25726H5.61563L6.98897 7.0788H5.92512H5.93479Z"
                                                fill="#FC6C53"
                                            />
                                        </svg>
                                    </td>
                                    <td>{chef.email}</td>
                                    <td className="ta-center">
                                        <MaterialSymbol
                                            icon={chef.active ? 'check_circle' : 'cancel'}
                                            size={24}
                                            grade={-25}
                                            color={chef.active ? 'green' : 'red'}
                                        />
                                    </td>
                                    <td className="ta-center">
                                        <MaterialSymbol
                                            icon={chef.verified ? 'check_circle' : 'cancel'}
                                            size={24}
                                            grade={-25}
                                            color={chef.verified ? 'green' : 'red'}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}
