import React, { useEffect, useState } from 'react';
import translate from 'translate';
import { FormGroup, Label, Input, Form, Button, Card, Row, Col, Spinner, Table } from 'reactstrap';
import ImageInput from 'components/imageInput';

export default function IntoleranceForm(props) {
    const { onClose, onSave, intolerance, languages, suggestedOrder } = props;

    const [order, setOrder] = useState(0);
    const [iconPath, setIconPath] = useState(null);
    const [image, setImage] = useState(null);

    const [translations, setTranslations] = useState([]);
    const [translating, setTranslating] = useState(false);
    const [originalTranslation, setOriginalTranslation] = useState(null);

    useEffect(() => {
        if (intolerance) {
            setTranslations(
                languages.map((item) => ({
                    language: item,
                    translation:
                        intolerance.translations.find(
                            (translation) => translation.language.id === item.id
                        )?.title ?? '',
                }))
            );
            setOrder(intolerance.order);
            setIconPath(intolerance.iconPath);
        } else {
            setTranslations(
                languages.map((item) => ({
                    language: item,
                    translation: '',
                }))
            );
            setOrder(suggestedOrder ? suggestedOrder : 0);
            setIconPath(null);
        }
    }, [intolerance, languages, suggestedOrder]);

    async function getOtherTitles(original) {
        if (!original) {
            return;
        }

        let newTranslations = [...translations];

        for (let index = 0; index < translations.length; index++) {
            const element = translations[index];
            if (element.language.id !== original.language.id) {
                let translatedTitle = await translate(original.translation, {
                    from: original.language.languageCode,
                    to: element.language.languageCode,
                });
                newTranslations[index] = {
                    ...element,
                    translation: translatedTitle,
                };
            }
        }

        setTranslations(newTranslations);
        setTranslating(false);
        setOriginalTranslation(null);
    }

    return (
        <Card style={{ padding: '1em' }}>
            <Row>
                <Form>
                    <ImageInput
                        iconPath={iconPath}
                        setIconPath={setIconPath}
                        image={image}
                        setImage={setImage}
                        size={'image'}
                    />
                    <FormGroup>
                        <Label htmlFor="intolerance_name">Názov</Label>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Jazyk</th>
                                    <th>Preklad</th>
                                </tr>
                            </thead>
                            <tbody>
                                {translations.map((translation) => (
                                    <tr key={translation.language.id}>
                                        <td>{`${translation.language.title} (${translation.language.languageCode})`}</td>
                                        <td>
                                            {translating &&
                                                translation.language.id !==
                                                    originalTranslation.language.id && <Spinner />}
                                            {(!translating ||
                                                translation.language.id ===
                                                    originalTranslation.language.id) && (
                                                <>
                                                    <Input
                                                        id={`intolerance_translation_for_${translation.language.languageCode}`}
                                                        disabled={translating}
                                                        value={translation.translation}
                                                        onChange={(e) => {
                                                            setTranslations(
                                                                translations.map((item) => {
                                                                    if (
                                                                        item.language.id ===
                                                                        translation.language.id
                                                                    ) {
                                                                        return {
                                                                            ...item,
                                                                            translation:
                                                                                e.target.value,
                                                                        };
                                                                    }
                                                                    return item;
                                                                })
                                                            );
                                                        }}
                                                    />
                                                    {translation.translation.length > 0 && (
                                                        <Button
                                                            color="link"
                                                            onClick={() => {
                                                                setTranslating(true);
                                                                setOriginalTranslation(translation);
                                                                getOtherTitles(translation);
                                                            }}
                                                        >
                                                            Preložiť podľa tohto prekladu
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="intolerance_order">Poradie</Label>
                        <Input
                            id={'intolerance_order'}
                            type="number"
                            value={order}
                            onChange={(e) => {
                                setOrder(e.target.value);
                            }}
                        />
                    </FormGroup>
                </Form>
            </Row>

            <Row xs="2">
                <Col>
                    <Button color="secondary" block onClick={onClose}>
                        Zrušiť
                    </Button>
                </Col>
                <Col>
                    <Button
                        color="primary"
                        block
                        disabled={
                            translations.some(
                                (translation) => translation.translation.length === 0
                            ) ||
                            (!iconPath && !image)
                        }
                        onClick={() => {
                            onSave({
                                id: intolerance?.id,
                                translations,
                                order: parseInt(order),
                                icon: image,
                            });
                        }}
                    >
                        Uložiť
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}
