import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import Profile from '../components/profile';
import { useParams } from 'react-router-dom';
import { Button, Col, Row, Spinner, Alert } from 'reactstrap';

import {
    GET_USER,
    UPDATE_USER,
    CHANGE_USER_ACTIVE,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
} from 'queries';
import { useGetMyData, isEmail } from 'utils';

export default function CustomerProfileContainer() {
    const { customerID } = useParams();

    const currentAdmin = useGetMyData();

    const [updateCustomer] = useMutation(UPDATE_USER);
    const [changeUserActive] = useMutation(CHANGE_USER_ACTIVE);

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const {
        data: customerData,
        loading: customerLoading,
        refetch: customerRefetch,
    } = useQuery(GET_USER, {
        variables: {
            id: parseInt(customerID),
        },
        fetchPolicy: 'network-only',
    });

    const [email, setEmail] = useState('');
    const [wrongEmailFormat, setWrongEmailFormat] = useState(false);

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [password1Visible, setPassword1Visible] = useState(false);
    const [password2Visible, setPassword2Visible] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState([]);

    const [active, setActive] = useState(false);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [applicationLanguage, setApplicationLanguage] = useState(null);
    const [videoLanguage, setVideoLanguage] = useState(null);
    const [subtitleLanguage, setSubtitleLanguage] = useState(null);
    const [notificationsThroughEmail, setNotificationsThroughEmail] = useState(true);
    const [notificationsThroughSms, setNotificationsThroughSms] = useState(true);
    const [image, setImage] = useState(null);
    const [iconPath, setIconPath] = useState(null);

    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);

    const onDismissSaved = () => setSaved(false);

    useEffect(() => {
        customerRefetch({
            userId: parseInt(customerID),
        });
    }, [customerID]);

    useEffect(() => {
        if (customerData && customerData.user && languagesData && languagesData.languages) {
            setData();
        }
    }, [customerData, languagesData]);

    const setData = () => {
        setActive(customerData.user.active);
        setEmail(customerData.user.email);
        setName(customerData.user.name);
        setSurname(customerData.user.surname);
        setPhoneNumber(customerData.user.phoneNumber);

        for (let index = 0; index < languagesData.languages.length; index++) {
            const language = languagesData.languages[index];
            if (language.id === customerData.user.applicationLanguage?.id) {
                setApplicationLanguage({
                    ...language,
                    value: language.id,
                    label: language.title,
                });
            }
            if (language.id === customerData.user.subtitleLanguage?.id) {
                setSubtitleLanguage({
                    ...language,
                    value: language.id,
                    label: language.title,
                });
            }
            if (language.id === customerData.user.videoLanguage?.id) {
                setVideoLanguage({
                    ...language,
                    value: language.id,
                    label: language.title,
                });
            }
        }

        setNotificationsThroughEmail(customerData.user.notificationsThroughEmail);
        setNotificationsThroughSms(customerData.user.notificationsThroughSms);
        setIconPath(customerData.user.iconPath);
        setImage(null);
    };

    const changeUserActiveFunc = () => {
        changeUserActive({
            variables: {
                id: parseInt(customerID),
            },
        }).then(() => {
            setSaving(false);
            setSaved(true);
        });
    };

    const updateCustomerFunc = () => {
        let dataToSave = {
            id: parseInt(customerID),
            name,
            surname,
            phoneNumber,
            applicationLanguageId: applicationLanguage.value,
            videoLanguageId: videoLanguage ? videoLanguage.value : applicationLanguage.value,
            subtitleLanguageId: subtitleLanguage
                ? subtitleLanguage.value
                : applicationLanguage.value,
            notificationsThroughEmail,
            notificationsThroughSms,
        };
        if (email !== customerData.user.email) {
            dataToSave = {
                ...dataToSave,
                email,
            };
        }
        if (password) {
            dataToSave = {
                ...dataToSave,
                password,
            };
        }
        if (image) {
            dataToSave = {
                ...dataToSave,
                icon: image,
            };
        }
        updateCustomer({
            variables: dataToSave,
        }).then(() => {
            setSaving(false);
            setSaved(true);
        });
    };

    const cannotSave =
        name.length === 0 ||
        surname.length === 0 ||
        phoneNumber.length === 0 ||
        applicationLanguage === null ||
        passwordErrors.length > 0 ||
        !isEmail(email);

    if (languagesLoading || customerLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <Profile
                customerID={parseInt(customerID)}
                active={active}
                setActive={setActive}
                changeUserActiveFunc={changeUserActiveFunc}
                name={name}
                setName={setName}
                surname={surname}
                setSurname={setSurname}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                applicationLanguage={applicationLanguage}
                setApplicationLanguage={setApplicationLanguage}
                videoLanguage={videoLanguage}
                setVideoLanguage={setVideoLanguage}
                subtitleLanguage={subtitleLanguage}
                setSubtitleLanguage={setSubtitleLanguage}
                notificationsThroughEmail={notificationsThroughEmail}
                setNotificationsThroughEmail={setNotificationsThroughEmail}
                notificationsThroughSms={notificationsThroughSms}
                setNotificationsThroughSms={setNotificationsThroughSms}
                iconPath={iconPath}
                setIconPath={setIconPath}
                image={image}
                setImage={setImage}
                email={email}
                setEmail={setEmail}
                wrongEmailFormat={wrongEmailFormat}
                setWrongEmailFormat={setWrongEmailFormat}
                password={password}
                setPassword={setPassword}
                password2={password2}
                setPassword2={setPassword2}
                password1Visible={password1Visible}
                setPassword1Visible={setPassword1Visible}
                password2Visible={password2Visible}
                setPassword2Visible={setPassword2Visible}
                passwordErrors={passwordErrors}
                setPasswordErrors={setPasswordErrors}
                languagesOptions={languagesData.languages.map((lang) => ({
                    ...lang,
                    value: lang.id,
                    label: lang.title,
                }))}
                currentAdmin={currentAdmin}
            />
            {!active && (
                <Row xs="2">
                    <Col>
                        <Button
                            color="secondary"
                            onClick={() => {
                                setSaving(true);
                                changeUserActiveFunc();
                            }}
                        >
                            Znovuaktivovať používateľa
                        </Button>
                    </Col>
                    <Col className="flex jc-flex-end"></Col>
                </Row>
            )}
            <Alert color="info" isOpen={saved} toggle={onDismissSaved}>
                Zmeny boli uložené!
            </Alert>
            <Row xs="2">
                <Col>
                    <Button color="secondary" onClick={setData}>
                        Zrušiť
                    </Button>
                </Col>
                <Col className="flex jc-flex-end">
                    <Button
                        color="primary"
                        disabled={cannotSave}
                        onClick={() => {
                            setSaving(true);
                            updateCustomerFunc();
                        }}
                    >
                        {saving && <Spinner color="light" size="sm"></Spinner>}
                        {!saving ? 'Uložiť' : ''}
                    </Button>
                </Col>
            </Row>
        </Col>
    );
}
