import { gql } from '@apollo/client';

export const GLOBAL_INGREDIENT_CONVERSIONS = gql`
    query globalIngredientConversions {
        globalIngredientConversions {
            toUnit
            multiplier
        }
    }
`;

export const GLOBAL_INGREDIENT_CONVERSION = gql`
    query globalIngredientConversions($id: Int!) {
        globalIngredientConversions(id: $id) {
            id
            toUnit
            multiplier
        }
    }
`;

export const ADD_OR_UPDATE_GLOBAL_INGREDIENT_CONVERSION = gql`
    mutation addOrUpdateGlobalIngredientConversion(
        $toUnit: IngredientUnitEnum!
        $multiplier: Float!
    ) {
        addOrUpdateGlobalIngredientConversion(toUnit: $toUnit, multiplier: $multiplier) {
            toUnit
        }
    }
`;
