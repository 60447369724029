import React, { useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';
import Ingredients from './list';
import { Col, Input, Row, Spinner, Button } from 'reactstrap';
import {
    INGREDIENTS,
    INGREDIENTS_SUBSCRIPTION,
    ADD_INGREDIENT,
    UPDATE_INGREDIENT,
    DELETE_INGREDIENT,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
} from 'queries';

const limit = 100;

export default function IngredientsContainer() {
    const [addIngredient] = useMutation(ADD_INGREDIENT);
    const [updateIngredient] = useMutation(UPDATE_INGREDIENT);
    const [deleteIngredient] = useMutation(DELETE_INGREDIENT);

    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');

    const {
        data: ingredientsData,
        loading: ingredientsLoading,
        refetch: ingredientsRefetch,
    } = useQuery(INGREDIENTS, {
        variables: {
            offset: page * limit,
            limit,
            search: '',
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(INGREDIENTS_SUBSCRIPTION, {
        onData: () => {
            ingredientsRefetch({
                offset: page * limit,
                limit,
                search,
            });
        },
    });

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const pages = useMemo(() => {
        if (!ingredientsData || !ingredientsData.ingredients) {
            return [];
        }
        let result = [];
        /*  for (let index = 0; index < Math.ceil(ingredientsData.ingredients.count / limit); index++) {
            result.push({
                label: index + 1,
                value: index,
            });
        }*/
        return result;
    }, [ingredientsData]);

    const onCreateIngredient = (data) => {
        addIngredient({
            variables: {
                allergens: data.allergens.map((allergen) => allergen.value),
                averagePrice:
                    parseFloat(data.averagePrice) > 0
                        ? parseFloat(parseFloat(data.averagePrice).toFixed(2))
                        : 0.0,
                categoryId: data.categoryId,
                fats: parseFloat(data.fats),
                fiber: parseFloat(data.fiber),
                intoleranceIds: data.intolerances.map((intolerance) => intolerance.value),
                kcal: parseFloat(data.kcal),
                proteins: parseFloat(data.proteins),
                saccharides: parseFloat(data.saccharides),
                sugars: parseFloat(data.sugars),
                translations: data.translations.map((translation) => ({
                    title: translation.title,
                    languageId: translation.language.id,
                    allAlternatives: translation.allAlternatives,
                })),
                visible: data.visible,
                type: data.type,
                defaultDaysUntilExpiry: data.defaultDaysUntilExpiry,
            },
        });
    };

    const onUpdateIngredient = (data) => {
        updateIngredient({
            variables: {
                id: data.id,
                allergens: data.allergens.map((allergen) => allergen.value),
                averagePrice:
                    parseFloat(data.averagePrice) > 0
                        ? parseFloat(parseFloat(data.averagePrice).toFixed(2))
                        : 0.0,
                categoryId: data.categoryId,
                fats: parseFloat(data.fats),
                fiber: parseFloat(data.fiber),
                intoleranceIds: data.intolerances.map((intolerance) => intolerance.value),
                kcal: parseFloat(data.kcal),
                proteins: parseFloat(data.proteins),
                saccharides: parseFloat(data.saccharides),
                sugars: parseFloat(data.sugars),
                translations: data.translations.map((translation) => ({
                    title: translation.title,
                    languageId: translation.language.id,
                    allAlternatives: translation.allAlternatives,
                })),
                visible: data.visible,
                type: data.type,
                defaultDaysUntilExpiry: data.defaultDaysUntilExpiry,
                problematic: data.problematic,
                fixed: data.fixed,
                verified: data.verified,
            },
        });
    };

    const onRemoveIngredient = (id) => {
        deleteIngredient({
            variables: {
                id: id,
            },
        });
    };

    if (ingredientsLoading || languagesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <Input
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                            />
                        </Col>
                        <Col xs="auto w-15">
                            <Button
                                className="ms-2"
                                color="danger"
                                onClick={() => {
                                    setSearch('');
                                    ingredientsRefetch({
                                        search: '',
                                        limit,
                                        offset: page * limit,
                                    });
                                }}
                            >
                                X
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Button
                        className="w-25 ms-2"
                        color="primary"
                        onClick={() => {
                            ingredientsRefetch({
                                search,
                                limit,
                                offset: page * limit,
                            });
                        }}
                    >
                        Hľadať podľa názvu
                    </Button>
                </Col>
            </Row>
            <Ingredients
                ingredients={
                    ingredientsData && ingredientsData.ingredients
                        ? ingredientsData.ingredients
                        : []
                }
                languages={
                    languagesData && languagesData.languages
                        ? languagesData.languages.map((lang) => ({
                              ...lang,
                              label: lang.title,
                              value: lang.id,
                          }))
                        : []
                }
                onCreateIngredient={onCreateIngredient}
                onUpdateIngredient={onUpdateIngredient}
                onRemoveIngredient={onRemoveIngredient}
                pages={pages}
                page={page}
                setPage={setPage}
            />
        </Col>
    );
}
