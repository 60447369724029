import React, { useState } from 'react';
import { Table, Row, Col, Modal, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import IngredientForm from './form';
import IngredientLoader from './loader';
import { MaterialSymbol } from 'react-material-symbols';

export default function Ingredients(props) {
    const {
        ingredients,
        languages,
        onCreateIngredient,
        onUpdateIngredient,
        onRemoveIngredient,
        pages,
        page,
        setPage,
    } = props;

    const [editIngredient, setEditIngredient] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };
    //cena, nutri, alergeny
    return (
        <>
            <Pagination style={{ marginTop: '1em' }}>
                <PaginationItem>
                    <PaginationLink
                        first
                        onClick={() => {
                            setPage(0);
                        }}
                    />
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        previous
                        onClick={() => {
                            setPage(page - 1);
                        }}
                    />
                </PaginationItem>

                {pages.map((p) => (
                    <PaginationItem
                        key={p.value}
                        onClick={() => {
                            setPage(p.value);
                        }}
                        active={page === p.value}
                    >
                        <PaginationLink tag={'button'}>{p.label}</PaginationLink>
                    </PaginationItem>
                ))}

                <PaginationItem>
                    <PaginationLink
                        onClick={() => {
                            setPage(page + 1);
                        }}
                        next
                    />
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        last
                        onClick={() => {
                            setPage(page + 1 /*Math.ceil(recipesData.recipes.count / limit) - 1*/);
                        }}
                    />
                </PaginationItem>
            </Pagination>
            <Table hover>
                <thead>
                    <tr>
                        <th>SK</th>
                        <th width="10%">Cena na 100g/ml</th>
                        <th width="10%">Kcal</th>
                        <th width="10%">Tuky</th>
                        <th width="10%">Sacharidy</th>
                        <th width="10%">Cukry</th>
                        <th width="10%">Proteiny</th>
                        <th width="10%">Alergeny</th>
                        <th width={'5%'}>Akcia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="add-ingredient-btn">
                        <td
                            colSpan={9}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setEditIngredient(null);
                                toggle();
                            }}
                        >
                            + Ingrediencia
                        </td>
                    </tr>
                    {ingredients.map((ingredient) => (
                        <tr
                            key={ingredient.id}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            <td key={`${ingredient.id}-sk`}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {ingredient.verified && (
                                        <MaterialSymbol
                                            icon={'verified'}
                                            size={24}
                                            grade={-25}
                                            color={'green'}
                                            style={{
                                                outline: 'none',
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                            }}
                                        />
                                    )}
                                    {ingredient.fixed && (
                                        <MaterialSymbol
                                            icon={'hourglass_bottom'}
                                            size={24}
                                            grade={-25}
                                            color={'orange'}
                                            style={{
                                                outline: 'none',
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                            }}
                                        />
                                    )}
                                    {ingredient.problematic && (
                                        <MaterialSymbol
                                            icon={'warning'}
                                            size={24}
                                            grade={-25}
                                            color={'red'}
                                            style={{
                                                outline: 'none',
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                            }}
                                        />
                                    )}
                                    <span>
                                        {ingredient.translations.find(
                                            (translation) => translation.language.id === 1
                                        )?.title ?? '-'}
                                    </span>
                                </div>
                            </td>
                            <th>
                                {typeof ingredient.averagePrice === 'number'
                                    ? ingredient.averagePrice.toFixed(2)
                                    : '-'}
                            </th>
                            <td>
                                {typeof ingredient.kcal === 'number'
                                    ? ingredient.kcal.toFixed(2)
                                    : '-'}
                            </td>
                            <td>
                                {typeof ingredient.fats === 'number'
                                    ? ingredient.fats.toFixed(2)
                                    : '-'}
                            </td>
                            <td>
                                {typeof ingredient.saccharides === 'number'
                                    ? ingredient.saccharides.toFixed(2)
                                    : '-'}
                            </td>
                            <td>
                                {typeof ingredient.sugars === 'number'
                                    ? ingredient.sugars.toFixed(2)
                                    : '-'}
                            </td>
                            <td>
                                {typeof ingredient.proteins === 'number'
                                    ? ingredient.proteins.toFixed(2)
                                    : '-'}
                            </td>
                            <td>{ingredient.allergens.join(', ')}</td>
                            <td>
                                <Row xs="2">
                                    <Col>
                                        <MaterialSymbol
                                            icon={'edit'}
                                            size={24}
                                            grade={-25}
                                            color={'orange'}
                                            style={{
                                                outline: 'none',
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                            }}
                                            onClick={() => {
                                                setEditIngredient(ingredient);
                                                toggle();
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <MaterialSymbol
                                            icon={'cancel'}
                                            size={24}
                                            grade={-25}
                                            color={'red'}
                                            style={{
                                                outline: 'none',
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                            }}
                                            onClick={() => {
                                                if (window.confirm('Ste si isty?')) {
                                                    onRemoveIngredient(ingredient.id);
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Pagination>
                <PaginationItem>
                    <PaginationLink
                        first
                        onClick={() => {
                            setPage(0);
                        }}
                    />
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        previous
                        onClick={() => {
                            setPage(page - 1);
                        }}
                    />
                </PaginationItem>

                {pages.map((p) => (
                    <PaginationItem
                        key={p.value}
                        onClick={() => {
                            setPage(p.value);
                        }}
                        active={page === p.value}
                    >
                        <PaginationLink tag={'button'}>{p.label}</PaginationLink>
                    </PaginationItem>
                ))}

                <PaginationItem>
                    <PaginationLink
                        onClick={() => {
                            setPage(page + 1);
                        }}
                        next
                    />
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        last
                        onClick={() => {
                            setPage(page + 1 /*Math.ceil(recipesData.recipes.count / limit) - 1*/);
                        }}
                    />
                </PaginationItem>
            </Pagination>

            <Modal
                isOpen={modalOpen}
                toggle={() => {
                    if (modalOpen) {
                        setEditIngredient(null);
                    }
                    toggle();
                }}
            >
                {editIngredient && (
                    <IngredientLoader
                        onClose={() => {
                            setEditIngredient(null);
                            toggle();
                        }}
                        onSave={(data, conversions) => {
                            onUpdateIngredient(data, conversions);
                            toggle();
                        }}
                        ingredientId={editIngredient ? editIngredient.id : null}
                        languages={languages}
                    />
                )}
                {!editIngredient && (
                    <IngredientForm
                        onClose={() => {
                            toggle();
                        }}
                        onSave={(data, conversions) => {
                            onCreateIngredient(data, conversions);
                            toggle();
                        }}
                        ingredientId={null}
                        languages={languages}
                    />
                )}
            </Modal>
        </>
    );
}
