import { gql } from '@apollo/client';

export const RECIPES_TO_TRANSLATE_SUBSCRIPTION = gql`
    subscription recipesSubscription {
        recipesSubscription
    }
`;

export const GET_RECIPES_TO_TRANSLATE = gql`
    query recipes(
        $visible: Boolean
        $chefId: Int
        $offset: Int
        $limit: Int
        $search: String
        $languageId: Int
        $ingredients: [Int!]
    ) {
        recipes(
            visible: $visible
            chefId: $chefId
            offset: $offset
            limit: $limit
            search: $search
            languageId: $languageId
            ingredients: $ingredients
        ) {
            count
            recipes {
                id
                chef {
                    name
                }
                recipeTranslations {
                    id
                    done
                    def
                    title
                    language {
                        id
                        languageCode
                        title
                    }
                }
                createdAt
                updatedAt
            }
        }
    }
`;

export const GET_RECIPE_TO_TRANSLATE = gql`
    query recipe($recipeId: Int!) {
        recipe(id: $recipeId) {
            id
            videoPath
            recipeSubtitles {
                language {
                    id
                }
                path
            }
            recipeTranslations {
                id
                title
                preparation {
                    id
                    order
                    text
                }
                description
                video
                done
                def
                language {
                    id
                    title
                    languageCode
                }
            }
        }
    }
`;

export const UPDATE_RECIPE_TRANSLATION = gql`
    mutation adminUpdateRecipeTranslation(
        $recipeId: Int!
        $languageId: Int!
        $done: Boolean
        $subtitles: String
        $title: String
        $description: String
        $preparation: [PreparationStepInput!]
    ) {
        adminUpdateRecipeTranslation(
            recipeId: $recipeId
            languageId: $languageId
            done: $done
            subtitles: $subtitles
            title: $title
            description: $description
            preparation: $preparation
        ) {
            id
        }
    }
`;

export const ADMIN_PUBLISH_RECIPE = gql`
    mutation confirmRecipeSubtitlesByAdmin($id: Int!, $rejected: Boolean!) {
        confirmRecipeSubtitlesByAdmin(id: $id, rejected: $rejected)
    }
`;
