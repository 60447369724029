import React from 'react';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';

import { useQuery, useMutation, useSubscription } from '@apollo/client';
import { GET_MY_DATA, ADMIN_DATA_SUBSCRIPTION, LOGOUT_ADMIN } from 'queries';
import { useGetMyData } from 'utils';
import { setIsLoggedIn } from 'apollo/localSchema/actions';

import { Container, Row, Col, Spinner } from 'reactstrap';
import PageHeader from 'pageHeader';
import Redirect from 'redirect';
/*import ShopsTable from 'pages/shops/table';
import ShopSidebar from 'pages/shops/sidebar';
import ShopAdd from 'pages/shops/add';
import Profile from 'pages/shops/edit/profileContainer';
import Products from 'pages/shops/edit/productsContainer';
*/
import SettingsSidebar from 'pages/settings/sidebar';
import AdminTable from 'pages/admins/table';
import SMTP from 'pages/settings/smtp';
import Languages from 'pages/settings/languages';
import APIKeys from 'pages/settings/apiKeys';
//import DistancePricing from 'pages/settings/distancePricing';
import TrendingGroupsContainer from 'pages/settings/trendingGroups';
import IntolerancesContainer from 'pages/settings/intolerances';
import IngredientCategoriesContainer from 'pages/settings/ingredientCategories';
import IngredientsContainer from 'pages/settings/ingredients';
import GlobalIngredientConversionsContainer from 'pages/settings/globalIngredientConversions';
//import ProductCategoriesContainer from 'pages/settings/productCategories';

import UsersTable from 'pages/users/table';
import UserAdd from 'pages/users/add';
import CustomerSidebar from 'pages/users/sidebar';
import CustomerProfile from 'pages/users/edit/profileContainer';
import CustomerAddresses from 'pages/users/edit/addressesContainer';

import ChefsTable from 'pages/chefs/table';
import ChefsSidebar from 'pages/chefs/sidebar';
import ChefsProfile from 'pages/chefs/edit/profileContainer';
import StatisticsSidebar from 'pages/system/sidebar';
//import OrderStatistics from 'pages/system/orders';
import Notifications from 'pages/system/notifications';
import PotentialChefsTable from 'pages/potentialChefs/table';
import PotentialChefProfile from 'pages/potentialChefs/edit/profileContainer';
import PotentialChefsSidebar from 'pages/potentialChefs/sidebar';
import ChefRecipesContainer from 'pages/chefs/edit/recipesContainer';

import CuisinesContainer from 'pages/settings/cuisines';
import DietsContainer from 'pages/settings/diets';
import MealTimesContainer from 'pages/settings/meal_times';
import MealTypesContainer from 'pages/settings/mealTypes';

import RecipeTranslationsTable from 'pages/recipeTranslations/table';
import RecipeTranslationsSidebar from 'pages/recipeTranslations/sidebar';
import RecipeDetail from 'pages/recipeTranslations/detail';

import NewIngredientsContainer from 'pages/potential_ingredients/table';

export default function Navigation() {
    const [logoutAdmin] = useMutation(LOGOUT_ADMIN);

    const { loading: adminLoading, refetch: adminRefetch } = useQuery(GET_MY_DATA, {
        fetchPolicy: 'network-only',
    });

    useSubscription(ADMIN_DATA_SUBSCRIPTION, {
        onData: () => {
            adminRefetch();
        },
    });

    const currentAdmin = useGetMyData();

    if (!adminLoading && currentAdmin === null) {
        logoutAdmin()
            .then(() => {
                setIsLoggedIn(false);
            })
            .catch(() => {
                setIsLoggedIn(false);
            });

        return null;
    }

    if (adminLoading) {
        return <Spinner color="primary"></Spinner>;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Redirect />} />

                <Route
                    path="/*"
                    element={
                        <Container fluid>
                            <PageHeader />

                            <Outlet />
                        </Container>
                    }
                >
                    {/*
                    <Route path="stores" element={<ShopsTable />} />

                    <Route path="stores/add" element={<ShopAdd />} />

                    <Route
                        path="stores/:shopID"
                        element={
                            <Row>
                                <Col xs="2">
                                    <ShopSidebar />
                                </Col>
                                <Col>
                                    <Outlet />
                                </Col>
                            </Row>
                        }
                    >
                        <Route path="profile" element={<Profile />} />
                        <Route path="products" element={<Products />} />
                    </Route>
*/}
                    <Route path="recipe-translations" element={<RecipeTranslationsTable />} />

                    <Route
                        path="recipe-translations/:recipeID"
                        element={
                            <Row>
                                <Col xs="2">
                                    <RecipeTranslationsSidebar />
                                </Col>
                                <Col>
                                    <RecipeDetail />
                                </Col>
                            </Row>
                        }
                    ></Route>

                    {currentAdmin.settings && (
                        <Route path="potential-ingredients" element={<NewIngredientsContainer />} />
                    )}

                    {currentAdmin.settings && (
                        <Route
                            path="settings"
                            element={
                                <Row>
                                    <Col xs="2">
                                        <SettingsSidebar />
                                    </Col>
                                    <Col>
                                        <Outlet />
                                    </Col>
                                </Row>
                            }
                        >
                            <Route path="admins" element={<AdminTable />} />

                            <Route path="smtp" element={<SMTP />} />

                            <Route path="keys" element={<APIKeys />} />

                            <Route path="languages" element={<Languages />} />

                            {/*                            <Route path="distance-pricing" element={<DistancePricing />} />*/}

                            <Route path="trending-groups" element={<TrendingGroupsContainer />} />

                            <Route path="intolerances" element={<IntolerancesContainer />} />

                            <Route
                                path="ingredient_conversions"
                                element={<GlobalIngredientConversionsContainer />}
                            />

                            <Route
                                path="ingredient_categories"
                                element={<IngredientCategoriesContainer />}
                            />
                            <Route path="ingredients" element={<IngredientsContainer />} />
                            {/*
                            <Route
                                path="product_categories"
                                element={<ProductCategoriesContainer />}
                            />*/}

                            <Route path="cuisines" element={<CuisinesContainer />} />

                            <Route path="diets" element={<DietsContainer />} />

                            <Route path="meal_times" element={<MealTimesContainer />} />

                            <Route path="meal_types" element={<MealTypesContainer />} />
                        </Route>
                    )}

                    {currentAdmin.settings && (
                        <Route
                            path="system"
                            element={
                                <Row>
                                    <Col xs="2">
                                        <StatisticsSidebar />
                                    </Col>
                                    <Col>
                                        <Outlet />
                                    </Col>
                                </Row>
                            }
                        >
                            {/* <Route path="orders" element={<OrderStatistics />} />*/}
                            <Route path="notifications" element={<Notifications />} />
                        </Route>
                    )}

                    <Route path="customers" element={<UsersTable />} />

                    <Route path="customers/add" element={<UserAdd />} />

                    <Route
                        path="customers/:customerID"
                        element={
                            <Row>
                                <Col xs="2">
                                    <CustomerSidebar />
                                </Col>
                                <Col>
                                    <Outlet />
                                </Col>
                            </Row>
                        }
                    >
                        <Route path="profile" element={<CustomerProfile />} />

                        <Route path="addresses" element={<CustomerAddresses />} />
                    </Route>
                    <Route path="chefs" element={<ChefsTable />} />

                    <Route
                        path="chefs/:chefID"
                        element={
                            <Row>
                                <Col xs="2">
                                    <ChefsSidebar />
                                </Col>
                                <Col>
                                    <Outlet />
                                </Col>
                            </Row>
                        }
                    >
                        <Route path="profile" element={<ChefsProfile />} />
                        <Route path="recipes" element={<ChefRecipesContainer />} />
                    </Route>

                    <Route path="potential-chefs" element={<PotentialChefsTable />} />

                    <Route
                        path="potential-chefs/:chefID"
                        element={
                            <Row>
                                <Col xs="2">
                                    <PotentialChefsSidebar />
                                </Col>
                                <Col>
                                    <Outlet />
                                </Col>
                            </Row>
                        }
                    >
                        <Route path="profile" element={<PotentialChefProfile />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
