import React, { useEffect, useState } from 'react';
import { Button, Spinner, Label, FormGroup, Input } from 'reactstrap';
import { REST_URL } from 'configs/restAPI';
import axios from 'axios';

export default function RecipeTranslationVideo(props) {
    const {
        recipeId,
        languageId,
        languageLabel,
        originalVideo,
        recipeRefetch,
        allTranslations,
        setAllTranslations,
    } = props;

    const [saving, setSaving] = useState(false);
    const [video, setVideo] = useState(null);

    useEffect(() => {
        setVideo(null);
    }, [originalVideo, languageId]);

    const uploadVideo = () => {
        setSaving(true);
        const formData = new FormData();

        formData.append(`file`, video);

        formData.append('token', `${sessionStorage.getItem('acctok')}`);
        formData.append('recipeId', parseInt(recipeId));
        formData.append('languageId', parseInt(languageId));
        try {
            axios
                .post(`${REST_URL}/api/admin_upload_recipe_translation_video`, formData, {
                    headers: {
                        'Authorization': `${sessionStorage.getItem('acctok')}`,
                        // 'Content-Type': 'multipart/form-data',
                    },
                })
                .then((resp) => {
                    /*
                                        const file =
                                            document.querySelector('#translation-video');
                                        file.value = '';*/
                    /*   let newAllTranslations = allTranslations.map((translation) => ({
                           ...translation,
                           translation: translation.language.id === languageId ? {
                               ...translation.translation,
                               video:  video.name,
                           } : translation.translation
                       }))
                       setAllTranslations(newAllTranslations)*/
                    recipeRefetch();
                    setSaving(false);
                });
        } catch (e) {
            console.log(e);
            setSaving(false);
        }
    };

    return (
        <>
            <FormGroup>
                <Label>Video</Label>
                {originalVideo && (
                    <p>
                        <a
                            href={originalVideo}
                            target="_blank"
                            rel="noreferrer"
                        >{`Originál - ${originalVideo}`}</a>
                    </p>
                )}
                {video && <p>{`${video.name}`}</p>}
                <>
                    <Label
                        htmlFor="translation-video"
                        style={{
                            display: 'block',
                            cursor: 'pointer',
                            color: 'blue',
                            textAlign: 'center',
                            textDecoration: 'underline',
                        }}
                    >
                        Kliknutím nahrajte {video ? 'nové' : ''} video
                    </Label>
                    <Input
                        id="translation-video"
                        type="file"
                        accept="video/*"
                        style={{ display: 'none' }}
                        onChange={(e) => {
                            let files = e.target.files;
                            setVideo(files[0]);
                        }}
                    />
                </>
            </FormGroup>
            <Button
                color={'success'}
                onClick={() => {
                    uploadVideo();
                }}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                {saving && <Spinner />}
                <span style={{ marginLeft: '10px' }}>
                    {saving
                        ? `Ukladám video pre ${languageLabel} preklad`
                        : `Uložiť video pre ${languageLabel} preklad`}
                </span>
            </Button>
        </>
    );
}
