import React from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import Intolerances from './list';
import { Col, Row, Spinner } from 'reactstrap';
import {
    ADD_INTOLERANCE,
    UPDATE_INTOLERANCE,
    DELETE_INTOLERANCE,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
    INTOLERANCES,
    INTOLERANCES_SUBSCRIPTION,
} from 'queries';

export default function IntolerancesContainer() {
    const [addIntolerance] = useMutation(ADD_INTOLERANCE);
    const [updateIntolerance] = useMutation(UPDATE_INTOLERANCE);
    const [deleteIntolerance] = useMutation(DELETE_INTOLERANCE);

    const {
        data: intolerancesData,
        loading: intolerancesLoading,
        refetch: intolerancesRefetch,
    } = useQuery(INTOLERANCES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(INTOLERANCES_SUBSCRIPTION, {
        onData: () => {
            intolerancesRefetch();
        },
    });

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const onCreateIntolerance = (data) => {
        addIntolerance({
            variables: {
                icon: data.icon,
                order: data.order,
                translations: data.translations.map((translation) => ({
                    title: translation.translation,
                    languageId: translation.language.id,
                })),
            },
        });
    };

    const onUpdateIntolerance = (data) => {
        let variables = {
            updateIntoleranceId: data.id,
            order: data.order,
            translations: data.translations.map((translation) => ({
                title: translation.translation,
                languageId: translation.language.id,
            })),
        };
        if (data.icon) {
            variables.icon = data.icon;
        }
        updateIntolerance({
            variables,
        });
    };
    const onRemoveIntolerance = (data) => {
        deleteIntolerance({
            variables: {
                deleteIntoleranceId: data.id,
                newId: data.replacementId,
            },
        });
    };

    if (intolerancesLoading || languagesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <Intolerances
                intolerances={
                    intolerancesData && intolerancesData.intolerances
                        ? intolerancesData.intolerances
                        : []
                }
                languages={
                    languagesData && languagesData.languages
                        ? languagesData.languages.map((lang) => ({
                              ...lang,
                              label: lang.title,
                              value: lang.id,
                          }))
                        : []
                }
                onCreateIntolerance={onCreateIntolerance}
                onUpdateIntolerance={onUpdateIntolerance}
                onRemoveIntolerance={onRemoveIntolerance}
            />
        </Col>
    );
}
